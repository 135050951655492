import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Button, Card, TextInput, Label, Select, Datepicker, Checkbox, Textarea } from 'flowbite-react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { createVoting, fetchVotingsAndGroups, editVoting, deleteVotingById } from '../../store/api'; // Update with correct paths
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiXCircle, HiChevronDown, HiChevronRight } from 'react-icons/hi2';
import VotingDetailsRow from '../../components/VotingDetailsRow';
import { useFormikContext } from 'formik';



const votingSchema = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  answers: Yup.array()
    .of(Yup.string())
    .test(
      'non-empty-array',
      'Answers is required',
      (array) => {
        return array && array.length > 0 && array.every(answer => { return answer !== undefined && answer && answer.trim() !== '' });
      }
    )
    .required('Group selection is required'),
  groups: Yup.array()
    .of(Yup.string().required('Group is required'))
    .min(1, 'At least one groups is required')
    .required('Group selection is required'),
  dateRange: Yup.array()
    .of(Yup.date().required('Date is required'))
    .test(
      'date-range',
      'Start and End dates are required',
      (dates) => dates && dates.length === 2 && dates[0] && dates[1] && dates[1] > dates[0]
    )
    .required('Date range is required'),
  isPublic: Yup.boolean()
});

const VotesManagement = () => {
  // ... state and functions from Step 1

  const [userAddIncrementer, setUserAddIncrementer] = useState(1);
  const [votings, setVotings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [modalError, setModalError] = useState('');
  const [expandedVotingId, setExpandedVotingId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialVotingData, setInitialVotingData] = useState(null);
  // const [initialValues, setInitialValues] = useState(newFormInitialValues);

  // Determine initial form values


  const initialValues = initialVotingData
  ? {
      ...initialVotingData,
      dateRange: [
        new Date(initialVotingData.startDate),
        new Date(initialVotingData.endDate)
      ],
      groups: initialVotingData.groups.map(group => group._id)
    }
  : {
    question: '',
    answers: [''],
    groups: [],
    dateRange: [null, null],
    isPublic: false
  };

  // Set edit mode if initial data is provided
  useEffect(() => {
    setIsEditMode(!!initialVotingData);
  }, [initialVotingData]);


  const loadVotings = async () => {
    try {
      const { groups, votings } = await fetchVotingsAndGroups();
      setGroups(groups);
      setVotings(votings);
    } catch (error) {
      setError(error.message);
    }
  };

  const clearError = () => {
    setError('');
  };

  const getVotingStatus = (startDate, endDate) => {
    const now = new Date();
    if (now < startDate) {
      return 'Pending';
    } else if (now > endDate) {
      return 'Finished';
    } else {
      return 'Running';
    }
  };

  const handleDeleteVoting = async (votingId) => {
    // Ask for confirmation
    const isConfirmed = window.confirm('Are you sure you want to delete this voting?');
    if (isConfirmed) {
      try {
        // Call your API to delete the voting
        await deleteVotingById(votingId);
        // Refresh the list of votings
        loadVotings();
      } catch (error) {
        console.error('Failed to delete voting:', error);
        // Set an error message if needed
        setError('Failed to delete voting. Please try again.');
      }
    }
  };


  const handleEditVoting = async (votingId) => {
    setInitialVotingData(votings.find(voting => voting.id === votingId));
    setShowModal(true);
  }

  const handleAddVotingClick = () => {

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalError(null);
    setShowModal(false);
    setInitialVotingData(null);

  };

  const handleRowClick = (votingId) => {
    setExpandedVotingId(expandedVotingId === votingId ? null : votingId);
  };

  const handleSubmit = async (values, actions) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    try {

      const { question, answers, groups, dateRange, isPublic } = values;


      // Format the data to match your MongoDB schema
      const formattedData = {
        question,
        answers,
        groups, // Assuming these are the ObjectIds of the groups
        startDate: dateRange[0] ? dateRange[0].getDate() + ' ' + monthNames[dateRange[0].getMonth()] + ' ' + dateRange[0].getFullYear() + " 00:00:00 UTC" : null,
        endDate: dateRange[1] ? dateRange[1].getDate() + ' ' + monthNames[dateRange[1].getMonth()] + ' ' + dateRange[1].getFullYear() + " 00:00:00 UTC" : null,
        isPublic
      };

      //this is for edit mode
      if(initialVotingData) {
        formattedData.id = initialVotingData.id;
        await editVoting(formattedData);
        setInitialVotingData(null);
      } else {
        await createVoting(formattedData);
      }
      setShowModal(false);
      setModalError('');
      setUserAddIncrementer(userAddIncrementer + 1);
      actions.resetForm();
      setIsEditMode(false);
    } catch (error) {
      setModalError(error.message);
    } finally {
      // setSubmitting(false);
    }
  };


  useEffect(() => {
    loadVotings();
  }, [userAddIncrementer]);


  return (

    <div>
      {/* Error message */}
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded relative">
          {error}
          <button
            onClick={clearError}
            className="absolute top-0 right-0 p-2 text-lg" // Adjust the styling as needed
            aria-label="Close"
          >
            <HiXCircle size={33} /> {/* Using the HiX icon */}
          </button>
        </div>
      )}


      {/* Button for adding new voting */}
      <div className="mb-4">
        <Button onClick={handleAddVotingClick}>
          Add New Voting
        </Button>
      </div>
      {/* Table to display votings */}
      <Table striped={true}>
        <Table.Head>
          <Table.HeadCell></Table.HeadCell>
          <Table.HeadCell>Question</Table.HeadCell>
          <Table.HeadCell>Groups</Table.HeadCell>
          <Table.HeadCell>Start Date</Table.HeadCell>
          <Table.HeadCell>End Date</Table.HeadCell>
          <Table.HeadCell>Is Public</Table.HeadCell>
          <Table.HeadCell>Status</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {votings.map(voting => {
            const hasVotes = Object.values(voting.votes).some(vote => vote.count > 0);
            const votingStatus = getVotingStatus(new Date(voting.startDate), new Date(voting.endDate));
            return (
              <React.Fragment key={voting.id}>
                <Table.Row>
                  <Table.Cell className="cursor-pointer" key={0} onClick={() => handleRowClick(voting.id)}>
                    {expandedVotingId === voting.id && <HiChevronDown />}
                    {expandedVotingId !== voting.id && <HiChevronRight />}

                  </Table.Cell>
                  <Table.Cell className="cursor-pointer" key={1} onClick={() => handleRowClick(voting.id)}>{voting.question}</Table.Cell>

                  <Table.Cell className="cursor-pointer" key={2} onClick={() => handleRowClick(voting.id)}>{
                    voting.groups.reduce((acc, group) => {
                      return acc + group.name + ', ';
                    }, '').slice(0, -2)
                  }
                  </Table.Cell>

                  <Table.Cell className="cursor-pointer" key={3} onClick={() => handleRowClick(voting.id)}>{new Date(voting.startDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  })}</Table.Cell>
                  <Table.Cell className="cursor-pointer" key={4} onClick={() => handleRowClick(voting.id)}>{new Date(voting.endDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  })}</Table.Cell>
                  <Table.Cell className="cursor-pointer" key={5} onClick={() => handleRowClick(voting.id)}>{voting.isPublic ? 'Yes' : 'No'}</Table.Cell>
                  <Table.Cell key={6}>
                    <div className="button-container">
                      {/* Status Tag */}
                      <span
                        style={{
                          display: 'inline-block',
                          padding: '0.5em 1em',
                          borderRadius: '9999px',
                          fontSize: '0.875rem',
                          lineHeight: '1.25rem',
                          fontWeight: '600',
                          backgroundColor: votingStatus === 'Pending' ? '#d6d13f' : votingStatus === 'Running' ? '#4CAF50' : 'darkgrey',
                          color: votingStatus === 'Pending' ? '#0F172A' : 'white',
                          textAlign: 'center',
                          minWidth: '75px',
                        }}
                      >
                        {votingStatus}
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell key={7} className="flex items-center justify-start space-x-2">

                    <div className="button-container">
                      {/* Edit Button - Show only if there are no votes */}
                      {(!hasVotes && votingStatus !== 'Finished') && (
                        <Button
                          onClick={() => handleEditVoting(voting.id)}
                          className="edit-button"
                        // Add more styles or classes as needed
                        >
                          Edit
                        </Button>
                      )}

                      <Button
                        onClick={() => handleDeleteVoting(voting.id)}
                        className="delete-button"
                      // Add more styles or classes as needed
                      >
                        Delete
                      </Button>
                    </div>
                  </Table.Cell>

                </Table.Row>
                {expandedVotingId === voting.id && <VotingDetailsRow voting={voting} />}
              </React.Fragment>
            )
          })}
        </Table.Body>
      </Table>


      {/* Modal for adding a new voting */}
      {showModal && (
        <Modal show={showModal} onClose={handleCloseModal}>
          <Modal.Header>
            Add New Voting
          </Modal.Header>
          <Modal.Body>
            <Card>
              {modalError && (
                <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
                  {modalError}
                </div>
              )}
              {/* Form for adding voting */}
              <Formik
                initialValues={initialValues}
                validationSchema={votingSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, isSubmitting }) => (
                  <Form className="flex flex-col gap-4">
                    {/* Question Field */}
                    <div className="mb-4">
                      <Label htmlFor="question">Question</Label>
                      <Field
                        as={Textarea}
                        id="question"
                        name="question"
                        rows="3"
                        placeholder="Enter your question here"
                      />
                      <ErrorMessage name="question" component="div" className="text-red-600" />
                    </div>

                    {/* Dynamic Fields for Answers */}
                    <FieldArray
                      name="answers"
                      render={arrayHelpers => (
                        <div>
                          {values.answers.map((answer, index) => (
                            <div key={index} className="flex items-center gap-2 mb-2">
                              <Field
                                as={TextInput}
                                name={`answers.${index}`}
                                type="text"
                                placeholder={`Answer ${index + 1}`}
                                className="flex-1"
                              />
                              <button
                                type="button"
                                className="p-1"
                                onClick={() => arrayHelpers.remove(index)}
                                disabled={values.answers.length === 1}
                              >
                                -
                              </button>
                              {index === values.answers.length - 1 && (
                                <button
                                  type="button"
                                  className="p-1"
                                  onClick={() => arrayHelpers.insert(index + 1, '')}
                                >
                                  +
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    />

                    {/* Groups Dropdown */}
                    <div>
                      <Label htmlFor="groups">Groups which participating in voting</Label>
                      <Field
                        as={Select}
                        name="groups"
                        multiple={true}
                      >
                        {groups.map(groups => (
                          <option key={groups.id} value={groups.id}>{groups.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="groups" component="div" className="text-red-600" />
                    </div>

                    {/* Date Pickers for Start and End Dates */}
                    <div className="mb-4 flex items-center gap-2">
                      <Label htmlFor="dateRange">Date Range</Label>
                      <Field name="dateRange">
                        {({ form, field }) => (
                          <DatePicker
                            selectsRange
                            startDate={field.value[0]}
                            endDate={field.value[1]}
                            onChange={update => form.setFieldValue(field.name, update)}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="dateRange" component="div" className="text-red-600" />
                    </div>

                    <Field name="isPublic">
                      {({ field, form }) => (
                        <div className="flex items-center gap-2">
                          <Checkbox
                            id="isPublic"
                            {...field}
                            checked={field.value}
                            onChange={() => form.setFieldValue('isPublic', !field.value)}
                          />
                          <Label htmlFor="isPublic">Is Public</Label>
                        </div>
                      )}
                    </Field>

                    {/* Submit Button */}
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Form>

                )}
              </Formik>
            </Card>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default VotesManagement;
