import { AUTHENTICATION_FAILURE } from "../utils/constants";

export const errorHandlingMiddleware = (store) => (next) => (action) => {

    //@TODO enable this in future when all calls will be done though redux
    // if(action['payload'] && action['payload']['code'] === AUTHENTICATION_FAILURE) {
    //     store.dispatch(userLoggedOut());
    // }

    return next(action);
};