// VotingDetailsRow.jsx

import React from 'react';
import { Table } from 'flowbite-react';

const VotingDetailsRow = ({ voting }) => {
  // Transform and sort answers by vote count
  const sortedAnswers = voting.answers
    .map((answer) => ({
      text: answer,
      votes: voting.votes[answer] ? voting.votes[answer].count : 0, // Assuming voting.votes is an object with answer texts as keys
      voters: voting.votes[answer] ? voting.votes[answer].voters : [], // Assuming voting.votes is an object with answer texts as keys
    }))
    .sort((a, b) => b.votes - a.votes); // Sort by vote count in descending order

  return (
    <Table.Row className="bg-gray-100">
      <Table.Cell colSpan={8}>
        <div className="p-4">
          <div className="font-semibold text-lg mb-2">Question:</div>
          <div className="mb-4">{voting.question}</div>
          <button onClick={() => downloadVotingResults(votingId)}>
        Download Voting Results
      </button>
          <div className="font-semibold text-lg mb-2">Answers:</div>
          <ul className="list-disc list-inside">
            {sortedAnswers.map((answer, index) => (
              <li key={index} className="mb-1 flex">
                <span>{answer.text}</span>
                <span>({answer.votes} votes)</span>
                {answer.voters && answer.voters.length > 0 && (<span style={{"color":"bluee"}} className={"ml-5"}> <span className={"bg-bluee-100"}>Voters:</span> {answer.voters.join(', ')}</span>)}
              </li>
            ))}
          </ul>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default VotingDetailsRow;
