export const AUTH_STATUS_ALREADY_LOGGED_IN = 800;
export const AUTH_STATUS_LOGIN_SUCCESS = 700;
export const AUTH_STATUS_LOGIN_FAILURE = 801;
export const AUTH_STATUS_LOGOUT_SUCCESS = 701;
export const AUTH_STATUS_LOGOUT_FAILURE = 802;
export const AUTHENTICATION_FAILURE = 803;
export const AUTHHORIZATION_FAILURE = 803;
export const STATUS_OK = 703;
export const STATUS_FAILURE = 704;

export const ADMINS_GROUP_NAME = 'Admins';