import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { csrfTokenGetRequest } from '../../store/api';
import * as yup from 'yup';
import { loginUser } from '../../store/userSlice';
import { TextInput, Label, Button, Card } from 'flowbite-react';
import { Navigate } from 'react-router-dom';
import { Link, Outlet, useNavigate } from 'react-router-dom';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [csrfToken, setCsrfToken] = useState('');

  const [csrfLoading, setCsrfLoading] = useState(true);
  const { loading, error, user } = useSelector((state) => state.auth);

  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate;

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        setCsrfLoading(true);
        setCsrfToken(await csrfTokenGetRequest());
      } catch (error) {
        console.error('Error fetching CSRF token', error);
      } finally {
        setCsrfLoading(false);
      }
    };

    fetchCsrfToken();
  }, []);

  // useEffect(() => {
  //   if (!user || user.group !== ADMINS_GROUP_NAME) {
  //     navigate('/');
  //   }
  // }, [user])


  // if (!user) {
  //   return <></>;
  // }

  const loginSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginSchema.validate({ username, password }, { abortEarly: false }
      );

      dispatch(loginUser({ username, password, csrfToken }));

    } catch (validationErrors) {
      if (validationErrors instanceof yup.ValidationError) {
        const errors = validationErrors.inner.reduce((acc, error) => {
          return { ...acc, [error.path]: error.message };
        }, {});

        setValidationErrors(errors);
      }
    }
  };



  if (user) {
    return (
      <Navigate to="/" />
    )
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-xs">
        <Card>
          <form onSubmit={handleSubmit} className="flex max-w-md flex-col gap-4">
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
                {error}
              </div>
            )}
            {/* <Form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"> */}
            <div className="mb-4">
              <Label htmlFor="username" value="Username" />
              <TextInput
                id="username"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                color={validationErrors.username ? "failure" : "gray"}
              />
              {validationErrors.username && <p className="text-red-500 text-xs italic">{validationErrors.username}</p>}
            </div>
            <div className="mb-6">
              <Label htmlFor="password" value="Password" />
              <TextInput
                id="password"
                type="password"
                placeholder="******************"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                color={validationErrors.password ? "failure" : "gray"}
              />
              {validationErrors.password && <p className="text-red-500 text-xs italic">{validationErrors.password}</p>}
            </div>
            <div className="flex items-center justify-between">
              <Button type="submit">
                Sign In
              </Button>
            </div>
          </form>
        </Card>
        {/* </Form> */}
      </div>
    </div>
  );
}

export default Login;
