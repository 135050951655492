import React, { useEffect, useState } from 'react';
import { Button, Navbar } from 'flowbite-react';
import { HiArrowLeftOnRectangle } from 'react-icons/hi2';
import { fetchUserVotings, submitVote } from '../../store/api';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { logoutUser } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const Votes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedVoting, setSelectedVoting] = useState(null);
  const [userAddIncrementer, setUserAddIncrementer] = useState(1);
  const [votings, setVotings] = useState([]);
  const [error, setError] = useState('');
  const [incrementer, setIncrementer] = useState(1);

  const { user } = useSelector((state) => state.auth);


  useEffect(() => {
    const loadUserVotings = async () => {
      try {
        const votings = await fetchUserVotings();
        setVotings(prevState => {
          return [...votings];
        });
        if (selectedVoting) {
          setSelectedVoting(votings.find(voting => voting.id === selectedVoting.id));
        }
      } catch (error) {
        setError(error.message);
      }
    };

    loadUserVotings();
  }, [incrementer]);

  const renderVotingForm = (voting) => {
    return (
      <Formik
        initialValues={{ answer: '' }}
        validate={values => {
          const errors = {};
          if (!values.answer) {
            errors.answer = 'Please select an answer';
          }
          return errors;
        }}
        onSubmit={(handleVoteSubmit(voting.id))}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <h3 className="text-xl font-semibold">{voting.question}</h3>
              {voting.answers.map((answer, index) => (
                <div key={index} className="my-2">
                  <Field type="radio" id={answer} name="answer" value={answer} />
                  <label htmlFor={answer} className="ml-2">{answer}</label>
                </div>
              ))}
              <ErrorMessage name="answer" component="div" className="text-red-500" />
              <Button type="submit" disabled={isSubmitting} className="mt-4 bg-blue-500 text-white px-1 py-1 rounded">
                Submit Vote
              </Button>
            </Form>
          )
        }}
      </Formik>
    );
  };

  const renderVotedInfo = (voting) => {
    return (
      <div>
        <h3 className="text-xl font-semibold">{voting.question}</h3>
        <p>You voted for: <strong>{voting.votedAnswer}</strong></p>
        <p>Voted on: {new Date(voting.votedAt).toLocaleString()}</p>
      </div>
    );
  };

  const handleVoteSubmit = (votingId) => async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await submitVote({ ...values, votingId });
      setIncrementer(prevState => prevState + 1);
      setError('');
      // Reload groups
      // setSelectedVoting(votingId);
    } catch (error) {
      setError(error.message)
      // setModalError(error.message);
    }
    setSubmitting(false);
  };




  if (!user) {
    return <><Navigate to="/" /></>;
  }


  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-1/6 border-r p-4">
        {votings.length > 0 ? (
          votings.map((voting) => (
            <div
              key={voting.id}
              onClick={() => setSelectedVoting(voting)}
              className={`p-2 cursor-pointer ${voting.votedAt ? 'text-green-500' : 'text-red-500'}`}
            >
              <div>{voting.question} <span className={"text-xs text-black"}>{voting.votedAt ? '(Voted)' : ''}</span></div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">There are no votings available.</div>
        )}
      </div>


      {/* Right Side (Navbar + Main Content) */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navbar */}
        <Navbar fluid rounded>
          <Navbar.Brand href="#">
            <img src="/logo.jpg" className="mr-3 h-6 sm:h-9" alt="Logo" />
            Voting Page
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Navbar.Link className={"capitalize"} href="#" onClick={(e) => e.preventDefault()}>{user.username} ({user.group})</Navbar.Link>
            <Navbar.Link href="#" onClick={(e) => { e.preventDefault(); dispatch(logoutUser()) }}>
              <HiArrowLeftOnRectangle size="22" />
            </Navbar.Link>
          </Navbar.Collapse>
        </Navbar>

        {/* Main content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white p-10">
          <div className="flex">
            {/* Voting Detail */}
            <div className="flex-grow">
              {error && (
                <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
                  {error}
                </div>
              )}
              {selectedVoting ? (
                selectedVoting.votedAnswer === false ?
                  renderVotingForm(selectedVoting) :
                  renderVotedInfo(selectedVoting)
              ) : (
                <p>Select a voting from the list</p>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Votes;
