import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ADMINS_GROUP_NAME } from "../utils/constants";
import { REFRESH_STATUS_FAILED, REFRESH_STATUS_IDLE, REFRESH_STATUS_PENDING, REFRESH_STATUS_SUCCESS, refreshUser, resetRefreshStatus } from "../store/userSlice";

//check user role and redirect to appropriate page
const UserNavigator = () => {
    const { user, refreshStatus } = useSelector((state) => state.auth);
    const [redirect, setRedirect] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            if (user.group === ADMINS_GROUP_NAME) {
                setRedirect("/admin/users") ;
            } else {
                setRedirect("/votes") ;
            }
            //if refreshStatus is success, this means we just refreshed user
            if(refreshStatus === REFRESH_STATUS_SUCCESS) {
                dispatch(resetRefreshStatus());
            }
        } else {

            //there is no user, lets try to refresh user
            if(refreshStatus === "idle") {
                dispatch(refreshUser());
            } else if(refreshStatus === REFRESH_STATUS_FAILED) {
                dispatch(resetRefreshStatus());
                setRedirect("/login") ;
            }
        }
    }, [refreshStatus]);

    if (redirect) {
        return <Navigate to={redirect} />;
    }
    return <div>Loading ...</div>;
}

export default UserNavigator;