import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Navbar, Sidebar } from 'flowbite-react';
import { HiAdjustmentsHorizontal, HiUsers, HiUserGroup, HiCheckCircle, HiArrowLeftOnRectangle } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../store/userSlice';
import { Navigate } from 'react-router-dom';
import { ADMINS_GROUP_NAME } from '../../utils/constants';

const AdminLayout = ({ children, element }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = React.useState(null);
    const { user } = useSelector((state) => state.auth);


    const navigateTo = (to) => (e) => {
        e.preventDefault();
        setPage(to)
        navigate(to);
    };


    if (!user || user.group !== ADMINS_GROUP_NAME) {
        return <><Navigate to="/" /></>;
    }
    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <Sidebar aria-label="Admin sidebar">
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item icon={HiAdjustmentsHorizontal}>
                            Dashboard
                        </Sidebar.Item>
                        <Sidebar.Item active={page === "/admin/users"} onClick={navigateTo("/admin/users")} href="#" icon={HiUsers}>
                            Users
                        </Sidebar.Item>
                        <Sidebar.Item active={page === "/admin/groups"} onClick={navigateTo("/admin/groups")} href="#" icon={HiUserGroup}>
                            Groups
                        </Sidebar.Item>
                        <Sidebar.Item active={page === "/admin/votings"} onClick={navigateTo("/admin/votings")} href="#" icon={HiCheckCircle}>
                            Votings
                        </Sidebar.Item>
                        <Sidebar.Item href="#" onClick = {(e) => {e.preventDefault(); dispatch(logoutUser())}} icon={HiArrowLeftOnRectangle}>
                            Logout
                        </Sidebar.Item>
                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>

            {/* Content area */}
            <div className="flex-1 flex flex-col overflow-hidden">
                {/* Navbar */}
                <Navbar fluid rounded>
                    <Navbar.Brand as={Link} href="https://flowbite-react.com">
                        <img src="/logo.jpg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className={"mr-10"}>
                    <Navbar.Link className={"capitalize"} href="#" onClick={(e) => e.preventDefault()}>{user.username}</Navbar.Link>
                        <Navbar.Link href="#" onClick = {(e) => {e.preventDefault(); dispatch(logoutUser())}}>
                            <HiArrowLeftOnRectangle size="22" />
                        </Navbar.Link>
                    </Navbar.Collapse>
                </Navbar>

                {/* Main content */}
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white p-10">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default AdminLayout;

