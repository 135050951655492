import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginRequest, logoutRequest, refreshUserRequest } from './api';
import { AUTH_STATUS_ALREADY_LOGGED_IN, AUTH_STATUS_LOGIN_SUCCESS, STATUS_OK } from '../utils/constants';

export const loginUser = createAsyncThunk (
    'user/login',
    async ( loginData ) => {
        try {
            const response = await loginRequest(loginData);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const refreshUser = createAsyncThunk (
    'user/refresh',
    async () => {
        try {
            const _user = getUserFromLocalStorage();
            //if we have user in localstorage, we do not need to refresh
            if(_user) {
                return {
                    code: STATUS_OK,
                    data: {
                        user: _user,
                    }
                }
            }
            const response = await refreshUserRequest();
            ("refreshUser response", response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// create  async action to logout user
export const logoutUser = createAsyncThunk (
    'user/logout',
    async ( ) => {
        try {
            const response = await logoutRequest();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


////////////////////
//private functions
////////////////////
const setUserToLocalStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

const getUserFromLocalStorage = () => {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
}

export const removeUserFromLocalStorage = () => {
    localStorage.removeItem('user');
}


////////////////////
// end of private functions
////////////////////


export const REFRESH_STATUS_IDLE = 'idle';
export const REFRESH_STATUS_PENDING = 'pending';
export const REFRESH_STATUS_SUCCESS = 'success';
export const REFRESH_STATUS_FAILED = 'failed';


const userSlice = createSlice({
    name: 'auth',
    initialState: {
        // user: userFromLocalStorage,

        //retrtieve user from localstorage, in case of refresh
        user: getUserFromLocalStorage(),
        loading: false,
        error: null,
        refreshStatus: 'idle',
    },
    reducers: {
        userLoggedOut: (state) => {
            removeUserFromLocalStorage();
            state.user = null;
            state.error = null;
            state.loading = false;
        },

        resetRefreshStatus: (state) => {
            state.refreshStatus = REFRESH_STATUS_IDLE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                // we always get 200 OK response even if login fails, so we need to check the code
                state.loading = false;
                state.error = null;
                const response = action.payload;
                if(response.code === AUTH_STATUS_LOGIN_SUCCESS) {
                    state.user = response.data.user;
                    setUserToLocalStorage(response.data.user);
                } else if(response.code === AUTH_STATUS_ALREADY_LOGGED_IN) {
                    state.authRedirectPath = '/';
                } else {
                    state.error = 'Login failed';
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                // when we get here, we know that the request failed, network error or server error
                state.loading = false;
                state.error = action.payload;
            })



            .addCase(refreshUser.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.refreshStatus = REFRESH_STATUS_PENDING;
            })
            .addCase(refreshUser.fulfilled, (state, action) => {
                // we always get 200 OK response even if login fails, so we need to check the code
                state.loading = false;
                state.error = null;
                state.refreshStatus = REFRESH_STATUS_SUCCESS;
                const response = action.payload;
                if(response.code === STATUS_OK) {
                    state.user = response.data.user;
                    setUserToLocalStorage(response.data.user);
                }
            })
            .addCase(refreshUser.rejected, (state, action) => {
                // when we get here, we know that the request failed, network error or server error
                state.loading = false;
                state.error = action.payload;
                state.refreshStatus = REFRESH_STATUS_FAILED;
            })



            .addCase(logoutUser.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(logoutUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null;
                state.error = null;
                removeUserFromLocalStorage();
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }

});


export default userSlice.reducer;
export const { userLoggedOut, resetRefreshStatus } = userSlice.actions;