import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./userSlice";

import { errorHandlingMiddleware } from './middleware'; // Your custom middleware

const store = configureStore({
  reducer: {auth: authReducer},
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorHandlingMiddleware),
});


export default store;
