import React, { useState, useEffect } from 'react';
import { Table, Card, Modal, Button, TextInput, Label } from 'flowbite-react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { createGroup, deleteGroup, editGroup, fetchUsersAndGroups } from '../../store/api'; // Update with correct paths

const groupSchema = Yup.object().shape({
  name: Yup.string().required('Group name is required'),
});

const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState('');
  const [modalError, setModalError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [groupAddIncrementer, setGroupAddIncrementer] = useState(1);
  const [editingGroup, setEditingGroup] = useState(null);

  const initialValues = editingGroup ? {
    ...editingGroup,
  } : { name: '' };

  const handleAddGroupClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEditGroupClick = (group) => {
    setEditingGroup(group);
    setShowModal(true);
  };

  const handleDeleteGroup = async (groupId) => {
    setModalError('');
    setError('');
    const isConfirmed = window.confirm('Are you sure you want to delete this group?');
    if (isConfirmed) {
      try {
        console.log('ewrew')
        await deleteGroup(groupId);
        setGroupAddIncrementer(groupAddIncrementer + 1);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      setModalError('');
      setError('');
      if (editingGroup) {
        await editGroup(values);
      } else {
        await createGroup(values);
      }
      setShowModal(false);
      setGroupAddIncrementer(groupAddIncrementer + 1);
    } catch (error) {
      setModalError(error.message);
    }
  };

  const loadGroups = async () => {
    try {
      const { groups } = await fetchUsersAndGroups();
      setGroups(groups);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadGroups();
  }, [groupAddIncrementer]);

  return (
    <div>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
          {error}
        </div>
      )}
      <div className="mb-4">
        <Button onClick={handleAddGroupClick}>
          Add New Group
        </Button>
      </div>
      <Table striped={true}>
        <Table.Head>
          <Table.HeadCell>Group Name</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {groups.map(group => (
            <Table.Row key={group.id}>
              <Table.Cell>{group.name}</Table.Cell>
              <Table.Cell>
                <div className="button-container1">
                  <Button
                    onClick={() => handleEditGroupClick(group)}
                    className="edit-button"
                  >
                    Edit
                  </Button>

                  <Button
                    onClick={() => handleDeleteGroup(group.id)}
                    className="delete-button"
                  >
                    Delete
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {showModal && (
        <Modal show={showModal} onClose={handleCloseModal}>
          <Modal.Header>
            Add New Group
          </Modal.Header>
          <Modal.Body>
            <Card>
              {modalError && (
                <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
                  {modalError}
                </div>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={groupSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="flex max-w-md flex-col gap-4">
                    <div className="mb-4">
                      <Label htmlFor="name">Group Name</Label>
                      <Field name="name" as={TextInput} type="text" placeholder="Enter group name" />
                      <ErrorMessage name="name" component="div" className="text-red-600" />
                    </div>
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Groups;
