import React, { useState, useEffect } from 'react';
import { Table, Card } from 'flowbite-react';
import { Modal, Button, TextInput, Label, Select } from 'flowbite-react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { createUser, deleteUser, editUser, fetchUsersAndGroups } from '../../store/api';

const createUserSchema = (isEdit) => {
  return Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: isEdit ?
      Yup.string().oneOf([Yup.ref('confirmPassword'), null], 'Passwords must match') :
      Yup.string().required('Password is required'),
    confirmPassword: isEdit ?
      Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match') :
      Yup.string().required('Conirm Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
    group: Yup.string().required('Group selection is required'),
  });
};




const Users = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState('');
  const [userAddIncrementer, setUserAddIncrementer] = useState(1);
  const [modalError, setModalError] = useState('');
  const [editingUser, setEditingUser] = useState(null); // null when adding, user object when editing
  const [showModal, setShowModal] = useState(false);

  const initialValues = editingUser ? {
    ...editingUser,
    password: '', confirmPassword: '',
    ['group']: groups.find(group => group.name === editingUser.group).id
  } : { username: '', password: '', confirmPassword: '', group: '' };



  const handleAddUserClick = () => {
    setShowModal(true);
  };

  const handleEditUserClick = (user) => {
    setEditingUser(user);
    setShowModal(true);
  };


  const handleDeleteUser = async (userId) => {
    setModalError('');
    setError('');
    const isConfirmed = window.confirm('Are you sure you want to delete this user?');
    if (isConfirmed) {
      try {
        await deleteUser(userId);
        setUserAddIncrementer(userAddIncrementer + 1);
      } catch (error) {
        setError(error.message);
      }
    }
  };


  const handleCloseModal = () => {
    setModalError('');

    setError('');
    setShowModal(false);
    setEditingUser(null);
  };



  const handleSubmit = async (values) => {
    try {

      setModalError('');
      setError('');
      if (editingUser) {
        await editUser(values);
      } else {
        await createUser(values);
      }

      setShowModal(false);
      setUserAddIncrementer(userAddIncrementer + 1);
    } catch (error) {
      setModalError(error.message);
    }
  }

  const loadUsersAndGroups = async () => {
    try {
      const { users, groups } = await fetchUsersAndGroups();
      setUsers(users);
      setGroups(groups);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadUsersAndGroups();
  }, [userAddIncrementer]);

  const handleAddUser = () => {
    // Implement logic to add a new user
  };

  return (
    <div>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
          {error}
        </div>
      )}
      <div className="mb-4">
        <Button onClick={handleAddUserClick}>
          Add New User
        </Button>
      </div>
      <Table striped={true}>
        <Table.Head>
          <Table.HeadCell>Username</Table.HeadCell>
          <Table.HeadCell>Group</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {users.map(user => (
            <Table.Row key={user.id}>
              <Table.Cell>{user.username}</Table.Cell>
              <Table.Cell>{user.group}</Table.Cell>
              <Table.Cell>
                {/* Edit and delete buttons */}

                <div className="button-container1">
                  {/* Edit Button - Show only if there are no votes */}

                  <Button
                    onClick={() => handleEditUserClick(user)}
                    className="edit-button"
                  >
                    Edit
                  </Button>

                  <Button
                    onClick={() => handleDeleteUser(user.id)}
                    className="delete-button"
                  >
                    Delete
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {showModal && (
        <Modal show={showModal} onClose={handleCloseModal}>
          <Modal.Header>
            Add New User
          </Modal.Header>
          <Modal.Body>
            <Card>
              {modalError && (
                <div className="mb-4 p-3 bg-red-100 text-red-700 border border-red-400 rounded">
                  {modalError}
                </div>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={createUserSchema(!!editingUser)}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="flex max-w-md flex-col gap-4">
                    <div className="mb-4">
                      <Label htmlFor="username">Username</Label>
                      <Field name="username" as={TextInput} type="username" placeholder="Enter username" />
                      <ErrorMessage name="username" component="div" className="text-red-600" />
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="password">Password</Label>
                      <Field name="password" as={TextInput} type="password" placeholder="Enter password" />
                      <ErrorMessage name="password" component="div" className="text-red-600" />
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="confirmPassword">Retype Password</Label>
                      <Field
                        name="confirmPassword"
                        as={TextInput}
                        type="password"
                        placeholder="Retype password"
                      />
                      <ErrorMessage name="confirmPassword" component="div" className="text-red-600" />
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="group">Group</Label>
                      <Field name="group" as="select" className="form-select mt-1 block w-full">
                        <option value="">Select a group</option>
                        {groups.map(group => (
                          <option value={group.id} key={group.id}>{group.name}</option>
                        ))}
                        {/* Other options */}
                      </Field>
                      <ErrorMessage name="group" component="div" className="text-red-600" />
                    </div>
                    <Button type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Users;
