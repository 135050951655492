import axios from 'axios';
import { AUTHENTICATION_FAILURE, STATUS_OK } from '../utils/constants';
import { removeUserFromLocalStorage } from './userSlice';


//response from backend should always be in a format of { code: 200, message: '', data: {}}

const API = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    withCredentials: true
});


//now lets prehandle responses to see if there is authentication error to redirect to login route
API.interceptors.response.use(
    (response) => {
        if (response.data.code === AUTHENTICATION_FAILURE) {
            removeUserFromLocalStorage();
            window.location.href = '/login';
            return;
        }
        return response;
    },
);

export const loadUsersRequest = async () => {
    try {
        const response = await API.get('/api/admin/users-and-groups');
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response;
    } catch (error) {
        console.error('Load users error:', error.response);
        throw new Error(error.message);
    }
}

export const exportVotingToExcell = async (votingId) => {
    try {
        const response = await API.get('/votings/'+votingId+'/export');
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response;
    } catch (error) {
        console.error('Error exporting voting to excell', error.response);
        throw new Error("Error exporting voting to excell");
    }
}

export const deleteVotingById = async (votingId) => {
    try {
        const response = await API.delete('/api/admin/votings/' + votingId);
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response;
    } catch (error) {
        console.error('Load users error:', error.response);
        throw new Error(error.message);
    }
}

export const deleteUser = async (userId) => {
    try {
        const response = await API.delete('/api/admin/users/' + userId);
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response;
    } catch (error) {
        console.error('Delete user error:', error.response);
        throw new Error(error.message);
    }
}

export const deleteGroup = async (groupId) => {
    try {
        const response = await API.delete('/api/admin/groups/' + groupId);
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response;
    } catch (error) {
        console.error('Delete group error:', error.response);
        throw new Error(error.message);
    }
}



// export const updateVotingStatus = async (votingId, newStatus) => {
//     // Replace with the actual API endpoint and request format
//     try {
//         const response = await API.put(`/api/admin/voting/${votingId}/status`, { status: newStatus });
//         if (response.data.code !== STATUS_OK) {
//             throw new Error(response.data.message);
//         }
//         return response;
//     } catch (error) {
//         console.error('Update voting status error:', error.response);
//         throw error;
//     }
//   };


//logout http request
export const logoutRequest = async () => {
    try {
        const response = await API.post('/api/auth/logout');
        return response;
    } catch (error) {
        console.error('Logout error:', error.response);
        throw error;
    }
};

export const loginRequest = async ({ username, password, csrfToken }) => {
    try {
        const response = await API.post('/api/auth/login', { username, password }, {
            headers: {
                'CSRF-Token': csrfToken,
            }
        });
        return response;
    } catch (error) {
        console.error('Login error:', error.response);
        throw error;
    }
};
export const refreshUserRequest = async () => {
    try {
        const response = await API.get('/api/auth/refresh');
        return response;
    } catch (error) {
        console.error('Refresh error:', error.response);
        throw error;
    }
};

export const checkSessionRequest = async () => {
    try {
        const response = await API.get('/api/auth/check-session');
        return response;
    } catch (error) {
        console.error('Check session error:', error.response);
        throw error;
    }
};

export const csrfTokenGetRequest = async () => {
    // await new Promise(resolve => setTimeout(resolve, 3000))
    const response = await API.get('api/auth/csrf-token', {
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });
    return response.data.data.csrfToken
};

//fetch users from backend
export const fetchUsersAndGroups = async () => {
    try {
        const response = await API.get('/api/admin/users-and-groups');
        //lets check if code is ok, otherwise trow error
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        const users = response.data.data.users.reduce((acc, user) => {
            acc.push({ id: user._id, username: user.username, group: user.group.name });
            return acc;
        }, []);

        const groups = response.data.data.groups.reduce((acc, group) => {
            acc.push({ id: group._id, name: group.name });
            return acc;
        }, []);
        return { users, groups };
    } catch (error) {
        throw new Error(error.message);
    }
};

export const fetchUserVotings = async () => {
    try {
        const response = await API.get('/api/user/votings-for-user');
        //lets check if code is ok, otherwise trow error
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data.votings.reduce((acc, voting) => {
            acc.push({ id: voting._id, votedAt: voting.votedAt, question: voting.question, answers: voting.answers, votedAnswer: voting.votedAnswer, endDate: voting.endDate });
            return acc;
        }, []);
    } catch (error) {
        throw new Error(error.message);
    }
};



//fetch votings and groups from backend
export const fetchVotingsAndGroups = async () => {
    try {
        const response = await API.get('/api/admin/votings-and-groups');
        //lets check if code is ok, otherwise trow error
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        const votings = response.data.data.votings.reduce((acc, voting) => {
            acc.push({
                id: voting._id, question: voting.question, answers: voting.answers, status: voting.status,
                groups: voting.groups, startDate: voting.startDate, endDate: voting.endDate, isPublic: voting.isPublic,
                potentialVoters: voting.potentialVoters, votes: voting.votes
            });
            return acc;
        }, []);

        const groups = response.data.data.groups.reduce((acc, group) => {
            acc.push({ id: group._id, name: group.name });
            return acc;
        }, []);
        return { votings, groups };
    } catch (error) {
        throw new Error(error.message);
    }
};

export const createUser = async ({ username, password, group }) => {
    try {
        const response = await API.post('/api/admin/users', { username, password, group });
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const editUser = async ({ id, username, password, group }) => {
    try {
        const response = await API.put('/api/admin/users', { id, username, password, group });
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const createGroup = async ({ name }) => {
    try {
        const response = await API.post('/api/admin/groups', { name });
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const editGroup = async ({ name, id }) => {
    try {
        const response = await API.put('/api/admin/groups/' + id, { name });
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const submitVote = async ({ votingId, answer }) => {
    try {
        const response = await API.post('/api/user/vote', { votingId, answer });
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


export const editVoting = async (data) => {
    try {
        const response = await API.put('/api/admin/votings/' + data.id, data);
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const createVoting = async (data) => {
    try {
        const response = await API.post('/api/admin/votings', data);
        if (response.data.code !== STATUS_OK) {
            throw new Error(response.data.message);
        }
        return response.data.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export default API;
