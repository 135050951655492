import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Users from './pages/Admin/Users';
import Groups from './pages/Admin/Groups';
import VotesManagement from './pages/Admin/VotesManagement';
import Votes from './pages/BasicUser/Votes';
import Login from './pages/Auth/Login';
import AdminLayout from './components/Layout/AdminLayout';
import UserNavigator from './components/UserNavigator';

//include app css
import './app.css';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<UserNavigator />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin/*" element={<AdminLayout />}>
                    <Route path="users" element={<Users />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="votings" element={<VotesManagement />} />
                </Route>
                <Route path="/votes" element={<Votes />} />
                <Route path="*" element={<div>Not Found</div>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
